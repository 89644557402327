
import GwHomeTemplate from '~/patterns/templates/home.vue';
import GwHero from '~/patterns/molecules/hero/hero.vue';
import GwButton from '~/patterns/atoms/button/button.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwCarousel from '~/patterns/molecules/carousel/carousel.vue';
import GwCourseList from '~/patterns/organisms/course-list/course-list.vue';
import GwStatList from '~/patterns/organisms/stat-list/presets/stat-list-hero.vue';
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwPageTitle from '~/patterns/molecules/page-title/page-title.vue';
import getHome from '~/graphql/queries/getHome.graphql';
import seo from '~/mixins/seo';
import GwSection from '~/patterns/organisms/section/section.vue';
import FlexibleSection from '~/patterns/organisms/_flexible-section/flexible-section';

export default {
    components: {
        GwHomeTemplate,
        GwPageContainer,
        GwPageTitle,
        GwButton,
        GwIcon,
        GwCarousel,
        GwCourseList,
        GwStatList,
        GwHero,
        GwSection,
        FlexibleSection,
    },
    mixins: [seo],
    layout: 'home',
    data() {
        return {
            entry: null,
            site: this.$config.site,
        };
    },
    async fetch() {
        const site = this.$config.appMultisiteHandle,
            { data } = await this.$gql.executeQuery(
                getHome,
                { site }
            );
        if (data && data.entry) {
            this.entry = data.entry;
        } else {
            this.notFound = true;
            if (process.server) {
                this.$nuxt.context.res.statusCode = 404;
            }
        }
    }
};
