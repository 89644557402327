
import GwPageContainer from '~/patterns/molecules/page-container/page-container';

export default {
    components: {
        GwPageContainer
    },

    props: {
        layout: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'text-image', 'video', 'home-intro', 'rich-text', '2-columns', 'call-to-action'].includes(value);
            }
        },

        orientation: {
            type: String,
            default: 'left',
            validator(value) {
                return ['left', 'right'].includes(value);
            }
        },

        width: {
            type: String,
            default: '',
            validator(value) {
                return ['content', 'page', ''].includes(value);
            }
        }
    }
};
