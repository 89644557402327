
import GwProgramCard from '~/patterns/atoms/card/presets/program-card.vue';
import DragToScroll from '~/patterns/atoms/drag-to-scroll/drag-to-scroll.vue';
import GwBackdrop from '~/patterns/atoms/backdrop/backdrop.vue';

export default {
    components: {
        GwProgramCard,
        DragToScroll,
        GwBackdrop
    },
    props: {
        items: {
            type: Array,
            default: () => { return []; }
        }
    }
};
