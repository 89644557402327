
import {
    hydrateOnInteraction,
    hydrateNever,
    hydrateWhenIdle,
    hydrateWhenVisible,
} from 'vue-lazy-hydration';

export default {
    props: {
        sections: {
            type: Array,
            required: true,
            default: () => { return []; }
        },

        contentPage: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data() {
        return {
            map: {
                flexibleSection_richText_BlockType: hydrateNever(() => import('./blocktypes/rich-text.vue')),
                flexibleSection_image_BlockType: hydrateWhenVisible(() => import('./blocktypes/image.vue')),
                flexibleSection_textImage_BlockType: hydrateWhenVisible(() => import('./blocktypes/text-image.vue')),
                flexibleSection_video_BlockType: hydrateNever(() => import('./blocktypes/video.vue')),
                flexibleSection_callToAction_BlockType: hydrateNever(() => import('./blocktypes/call-to-action.vue')),
                flexibleSection_accordion_BlockType: hydrateOnInteraction(() => import('./blocktypes/accordion.vue')),
                flexibleSection_quote_BlockType: hydrateNever(() => import('./blocktypes/quote.vue')),
                flexibleSection_inlineNavigation_BlockType: hydrateWhenIdle(() => import('./blocktypes/inline-navigation.vue')),
                flexibleSection_editionsSchedule_BlockType: hydrateWhenVisible(() => import('./blocktypes/editions-schedule.vue')),
                flexibleSection_textCtaImage_BlockType: hydrateWhenVisible(() => import('./blocktypes/text-cta-image.vue')),
                flexibleSection_trainers_BlockType: hydrateWhenVisible(() => import('./blocktypes/trainers.vue')),
                flexibleSection_form_BlockType: hydrateWhenIdle(() => import('./blocktypes/form.vue')),
                flexibleSection_cards_BlockType: hydrateWhenVisible(() => import('./blocktypes/cards.vue')),
                flexibleSection_htmlEmbed_BlockType: hydrateNever(() => import('./blocktypes/html-embed.vue')),
                flexibleSection_programTable_BlockType: hydrateWhenVisible(() => import('~/components/EventProgramTable.vue')),
            }
        };
    }
};
