
import GwDefaultTemplate from '~/patterns/templates/default.vue';
import IntersectionAnchor from '~/patterns/atoms/intersection-anchor/presets/header';

export default {
    components: {
        GwDefaultTemplate,
        IntersectionAnchor
    }
};
