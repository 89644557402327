
import GwCard from '~/patterns/atoms/card/card.vue';
import countPages from '~/graphql/queries/countPages.graphql';

export default {
    components: {
        GwCard,
    },
    props: {
        program: {
            type: Object,
            default: () => { return {}; }
        }
    },
    data() {
        return {
            count: 0,
        };
    },
    async fetch() {
        const site = this.$config.appMultisiteHandle,
            { data } = await this.$gql.executeQuery(
                countPages,
                { site, type: 'coursePage', programs: this.program.id }
            );

        this.count = data.entryCount;
    }
};
