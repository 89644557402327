
export default {
    props: {
        intersectionClass: {
            type: String,
            default: 'intersection-fired'
        }
    },
    mounted() {
        this.checkIntersection();
    },
    methods: {
        checkIntersection() {
            if (
                'IntersectionObserver' in window &&
                'IntersectionObserverEntry' in window &&
                'intersectionRatio' in window.IntersectionObserverEntry.prototype
            ) {
                const observer = new IntersectionObserver((entries) => {
                    if (entries[0].boundingClientRect.y < 0) {
                        document.body.classList.add(this.intersectionClass);
                    } else {
                        document.body.classList.remove(this.intersectionClass);
                    }
                });
                observer.observe(document.querySelector('#intersection-anchor'));
            }
        }
    }
};
