
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        GwIcon
    },
    props: {
        courses: {
            type: Array,
            default: () => { return []; }
        }
    },
};
