
import GwStatList from '~/patterns/organisms/stat-list/stat-list.vue';
import GwStatSlab from '~/patterns/atoms/stat-slab/stat-slab.vue';

export default {
    components: {
        GwStatList,
        GwStatSlab
    },
    data() {
        return {
            successRate: this.$config.site.group === 'highberg' ? '95%' : '50K',
            successText: this.$config.site.group === 'highberg' ? this.$t('stat-list.recommended-rate') : this.$t('stat-list.succesful-alumni'),
            leaderText: this.$config.site.group === 'highberg' ? this.$t('stat-list.organizations-participating') : this.$t('stat-list.market-leader'),
            trainerRate: this.$config.site.group === 'highberg' ? '50K+' : '72',
            trainerText: this.$config.site.group === 'highberg' ? this.$t('stat-list.participants-enrolled') : this.$t('stat-list.certified-trainers'),
        };
    }
};
