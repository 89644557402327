
export default {
    props: {
        labelPosition: {
            type: String,
            default: 'top',
            validator(value) {
                return ['top', 'bottom'].includes(value);
            }
        }
    },
    computed: {
        above() {
            return this.labelPosition === 'top';
        }
    }
};
